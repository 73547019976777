export const Branches = [
  {
    class: "tokyo-marker",
    name: "Japan",
    locations: [
      {
        name: "Tokyo Head Office",
        desc: "Hibiya Kokusai Building, 2-2-3 Uchisaiwaicho, Chiyoda-ku, Tokyo 100-0011, Japan",
      },
      {
        name: "Yokohama Head Office",
        desc: "2-1,Suehiro-cho,Tsurumi-ku,Yokohama 230-8611, Japan",
      },
    ],
  },
  // {
  //   class: "tokyo-marker",
  //   name: "Tokyo Head Office",
  //   desc: "Hibiya Kokusai Building, 2-2-3 Uchisaiwaicho, Chiyoda-ku, Tokyo 100-0011, Japan",
  // },
  // {
  //   class: "yokohama-marker",
  //   name: "Yokohama Head Office",
  //   desc: "2-1,Suehiro-cho,Tsurumi-ku,Yokohama 230-8611, Japan",
  // },
  // {
  //   class: "manila-marker",
  //   name: "JFE Engineering Corporation Philippine Branch",
  //   desc: "23rd Floor Robinsons Cyberscape Alpha Bldg., Sapphire & Garnet Road, Ortigas Center, Pasig City, Metro Manila, 1605, Philippines",
  // },
  // {
  //   class: "techno-manila-marker",
  //   name: "JFE Techno Manila, Inc.",
  //   desc: "23rd Floor Robinsons Cyberscape Alpha Bldg., Sapphire & Garnet Road, Ortigas Center, Pasig City, Metro Manila, 1605, Philippines",
  // },
  {
    class: "techno-manila-marker",
    name: "Philippines",
    locations: [
      {
        name: "JFE Techno Manila, Inc.",
        desc: "23rd Floor Robinsons Cyberscape Alpha Bldg., Sapphire & Garnet Road, Ortigas Center, Pasig City, Metro Manila, 1605, Philippines",
      },
      {
        name: "JFE Engineering Corporation Philippine Branch",
        desc: "23rd Floor Robinsons Cyberscape Alpha Bldg., Sapphire & Garnet Road, Ortigas Center, Pasig City, Metro Manila, 1605, Philippines",
      },
    ],
  },
  {
    class: "yangon-marker",
    name: "Myanmar",
    locations: [
      {
        name: "JFE Engineering Corporation Yangon Branch",
        desc: "05-05 05-06 Union Business Centre, Nat Mauk Road, Bo Cho Quarter, Bahan T/S, Yangon, Myanmar",
      },
      {
        name: "J&M  Steel Solutions Company Limited",
        desc: "No.237, Shukhinthar Mayopat Road, Block No.10, Thaketa Township, Yangon, The Republic of the Union of Myanmar",
      },
    ],
  },
  // {
  //   class: "yangon-marker",
  //   name: "JFE Engineering Corporation Yangon",
  //   desc: "05-05 05-06 Union Business Centre, Nat Mauk Road, Bo Cho Quarter, Bahan T/S, Yangon, Myanmar",
  // },
  // {
  //   class: "yangon2-marker",
  //   name: "J&M Steel Solutions Company Limited",
  //   desc: "No.237, Shukhinthar Mayopat Road, Block No.10, Thaketa Township, Yangon, The Republic of the Union of Myanmar",
  // },
  {
    class: "bangkok-marker",
    name: "Thailand",
    locations: [
      {
        name: "JFE Engineering Corporation Bangkok Office",
        desc: "18th Floor,Ramaland Bldg., 952 Rama IVRoad,Suriyawongse,Bangrak, Bangkok 10500, Thailand",
      },
      {
        name: "Mitr Project Services Co., Ltd.",
        desc: "22nd Floor, UM Tower, No.9 Ramkhamhaeng Road, Kwaeng Suanluang, Khet Suanluang, Bangkok 10250, Thailand",
      },
    ],
  },
  // {
  //   class: "bangkok-marker",
  //   name: "JFE Engineering Corporation Bangkok Office",
  //   desc: "18th Floor,Ramaland Bldg., 952 Rama IVRoad,Suriyawongse,Bangrak, Bangkok 10500, Thailand",
  // },
  // {
  //   class: "bangkok2-marker",
  //   name: "Mitr Project Services Co., Ltd.",
  //   desc: "22nd Floor, UM Tower, No.9 Ramkhamhaeng Road, Kwaeng Suanluang, Khet Suanluang, Bangkok 10250, Thailand",
  // },
  {
    class: "dhaka-marker",
    name: "Bangladesh",
    locations: [
      {
        name: "JFE Engineering Corporation, Bangladesh Branch Office",
        desc: "Taj Casilina Building 3rd floor of Plot No. SW(I)4, 25, Gulshan Avenue, Gulshan-1, Dhaka, Bangladesh",
      },
    ],
  },
  {
    class: "shanghai-marker",
    name: "China",
    locations: [
      {
        name: "DongJie Environmental Technology Co., Ltd.",
        desc: "Rm. 1501, Ruijin Building, No. 205, Maoming South Road, Huangpu, Shanghai, 200020, China",
      },
      {
        name: "JFE Engineering (Beijing) Co., Ltd",
        desc: "Room 2016, 20th Floor, Fortune Building No.5, Dong san huan Bei Lu, Chaoyang District, Beijing, 100004, China",
      },
    ],
  },
  {
    class: "beijing-marker",
    name: "China",
    locations: [
      {
        name: "DongJie Environmental Technology Co., Ltd.",
        desc: "Rm. 1501, Ruijin Building, No. 205, Maoming South Road, Huangpu, Shanghai, 200020, China",
      },
      {
        name: "JFE Engineering (Beijing) Co., Ltd",
        desc: "Room 2016, 20th Floor, Fortune Building No.5, Dong san huan Bei Lu, Chaoyang District, Beijing, 100004, China",
      },
    ],
  },
  {
    class: "ho-chi-minh-marker",
    name: "Vietnam",
    locations: [
      {
        name: "JFE Engineering Vietnam Company Limited",
        desc: "Room 1405, 14th Floor, SunWah Tower, 115 Nguyen Hue Street, Ben Nghe Ward, District1, Ho Chi Minh City, Vietnam",
      },
      {
        name: "JFE Engineering Vietnam Company Limited HANOI Branch",
        desc: "Unit 13-03, Prime Centre, 53 Quang Trung Street, Hai Ba Trung District, Hanoi, Vietnam",
      },
    ],
  },
  {
    class: "hanoi-marker",
    name: "Vietnam",
    locations: [
      {
        name: "JFE Engineering Vietnam Company Limited",
        desc: "Room 1405, 14th Floor, SunWah Tower, 115 Nguyen Hue Street, Ben Nghe Ward, District1, Ho Chi Minh City, Vietnam",
      },
      {
        name: "JFE Engineering Vietnam Company Limited HANOI Branch",
        desc: "Unit 13-03, Prime Centre, 53 Quang Trung Street, Hai Ba Trung District, Hanoi, Vietnam",
      },
    ],
  },
  {
    class: "singapore-marker",
    name: "Singapore",
    locations: [
      {
        name: "JFE Engineering (S) Pte. Ltd.",
        desc: "350 Orchard Road #19-04/05 Shaw House, Singapore 238868, Singapore",
      },
    ],
  },
  {
    class: "kuala-lumpur-marker",
    name: "Malaysia",
    locations: [
      {
        name: "JFE Engineering (M) Sdn. Bhd",
        desc: "Suite 9.01 & 9.02, 9th Floor, Menara JKG, No. 282, Jalan Raja Laut, 50350, Kuala Lumpur, Malaysia",
      },
    ],
  },
  {
    class: "id-marker",
    name: "Indonesia",
    locations: [
      {
        name: "PT. JFE Engineering Indonesia",
        desc: "Menara Sentraya, 22nd Floor\nJl. Iskandarsyah Raya No. 1A Kebayoran\nBaru, Jakarta Selatan 12160, Indonesia",
      },
      {
        name: "JFE Engineering Corporation Jakarta Representative Office",
        desc: "Menara Sentraya, 22nd Floor\nJl. Iskandarsyah Raya No. 1A Kebayoran\nBaru, Jakarta Selatan 12160, Indonesia",
      },
    ],
  },
  {
    class: "maharashtra-marker",
    name: "India",
    locations: [
      {
        name: "JFE Engineering India Private Limited",
        // desc: "ICC-Devi Gaurav Tech Park, Unit 01-A, S. No-191 to 195, North Block, Ground Floor, Pimpri, Pune-411018 Maharashtra, India",
      },
      {
        name: "JFE Engineering India Private Limited Pune Engineering Centre",
        desc: "ICC-Devi Gaurav Tech Park, Unit 01-A, S. No-191 to 195, North Block, Ground Floor, Pimpri, Pune-411018 Maharashtra, India",
      },
    ],
  },
  // {
  //   class: "maharashtra-marker",
  //   name: "JFE Engineering India Private Limited Pune Engineering Centre",
  //   desc: "ICC-Devi Gaurav Tech Park, Unit 01-A, S. No-191 to 195, North Block, Ground Floor, Pimpri, Pune-411018 Maharashtra, India",
  // },
  {
    class: "germany-marker",
    name: "Germany",
    locations: [
      {
        name: "Standardkessel Baumgarte Holding GmbH",
        desc: "Wissollstr. 19, 45478 Mülheim an der Ruhr, Germany",
      },
    ],
  },
  {
    class: "usa-marker",
    name: "America",
    locations: [
      {
        name: "JFE Engineering America Inc.",
        desc: "301E. Ocean Blvd., Suite #1750 Long Beach, CA 90802, U.S.A.",
      },
    ],
  },
]
