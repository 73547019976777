import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import Map from "../../../static/svg/JFE-Map-Final.svg"
import { MdLocationOn } from "react-icons/md"
import { Lethargy } from "lethargy"
import { useWheel } from "react-use-gesture"
import { Branches } from "./Branches"

const SvgMap = () => {
  const lethargy = new Lethargy()
  const clamp = (value, min, max) => Math.max(Math.min(max, value), min)
  const [scale, setScale] = useState(1)
  const [cardScale, setCardScale] = useState(1)
  const [cardTop, setCardTop] = useState(25)
  const [cardLeft, setCardLeft] = useState(0)
  const [cardBottom, setCardBottom] = useState(0)
  const [hidden, setHidden] = useState(1)

  const ref = useRef()

  const MarkerStyles = {
    display: "flex",
    height: 30,
    width: 30,
    transform: `scale(${scale})`,
  }

  useWheel(
    ({ event, last, memo: wait = false }) => {
      if (!last) {
        event.preventDefault()
        const s = lethargy.check(event)
        if (s) {
          if (!wait) {
            setHidden(0)
            setScale(() => clamp(s < 0 ? scale + 0.1 : scale - 0.1, 0.3, 1))
            setCardScale(() =>
              clamp(s < 0 ? cardScale + 0.1 : cardScale - 0.1, 0.5, 1)
            )
            setCardTop(() =>
              clamp(s < 0 ? cardTop + 10 : cardTop - 10, -25, 25)
            )
            setCardLeft(() =>
              clamp(s < 0 ? cardLeft + 15 : cardLeft - 15, -80, 0)
            )
            setCardBottom(() =>
              clamp(s < 0 ? cardBottom + 35 : cardBottom - 35, -180, 0)
            )

            return true
          } else return false
        } else return false
      } else {
        setHidden(1)
        return false
      }
    },
    { domTarget: ref }
  )

  return (
    <StyledContaier fluid>
      <MapWrapper>
        <Col className="column" lg="12" md="12" xs="12">
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={8}
            centerZoomedOut={true}
            panning={{ velocityDisabled: true }}
            doubleClick={{ disabled: true }}
            wheel={{ step: "0.5" }}
          >
            <TransformComponent>
              <div ref={ref}>
                <Map width={1080} height={720} />
                {Branches.map((branch, index) => (
                  <div
                    key={index}
                    className={`${branch.class} marker`}
                    style={MarkerStyles}
                  >
                    <MdLocationOn size={30} />
                    {branch.locations ? (
                      <BranchCard
                        bottom={`${cardBottom}px`}
                        left={`${cardLeft}px`}
                        scale={`${cardScale}`}
                        opacity={`${hidden}`}
                      >
                        <div className="header">
                          <h1>{branch.name}</h1>
                        </div>
                        <div className="desc">
                          {branch.locations.map((location, index) => (
                            <div key={index}>
                              <h1>{location.name}</h1>
                              <p>{location.desc}</p>
                            </div>
                          ))}
                        </div>
                      </BranchCard>
                    ) : (
                      <BranchCard
                        top={`${cardTop}px`}
                        scale={`${cardScale}`}
                        opacity={`${hidden}`}
                      >
                        <div className="header">
                          <h1>{branch.name}</h1>
                        </div>
                        <div className="desc">{branch.desc}</div>
                      </BranchCard>
                    )}
                  </div>
                ))}
              </div>
            </TransformComponent>
          </TransformWrapper>
        </Col>
      </MapWrapper>
    </StyledContaier>
  )
}

export default SvgMap

const StyledContaier = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .react-transform-wrapper {
    overflow: visible;
  }

  @media only screen and (max-width: 1367px) {
    transform: scale(0.7);
  }

  @media only screen and (max-width: 769px) {
    transform: scale(0.6);
  }

  @media only screen and (max-width: 500px) {
    transform: scale(0.4);
  }

  @media only screen and (max-width: 376px) {
    transform: scale(0.3);
  }
`

const BranchCard = styled.div`
  width: 440px;
  height: fit-content;
  background: #fff;
  box-shadow: 0px 5px 15px 10px rgba(0, 0, 0, 0.03);
  position: fixed;
  top: ${(props) => props.top || "-200px"};
  left: ${(props) => props.left || "-205px"};
  bottom: ${(props) => props.bottom || "unset"};
  transform: scale(${(props) => props.scale || 1});
  opacity: ${(props) => props.opacity || 1};
  border-radius: 5px;
  z-index: 999;
  visibility: hidden;

  .header {
    background: linear-gradient(180deg, #d1e869 0%, #aac532 100%);
    padding: 15px 30px;
    border-radius: 5px 5px 0 0;

    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #ffffff;
      margin: 0;
    }
  }

  .desc {
    padding: 30px;
    white-space: pre-wrap;

    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      letter-spacing: 0.06em;
      color: #5b5d5d;
    }

    p {
      margin-bottom: 25px;
    }
  }
`

const MapWrapper = styled(Row)`
  position: static;
  .marker {
    position: absolute;
    z-index: 1;

    svg {
      fill: #aac532;
      position: relative;
      z-index: 1;
    }

    &:hover {
      z-index: 2;
      ${BranchCard} {
        visibility: visible;
      }
    }
  }

  .tokyo-marker {
    left: 458px;
    top: 362px;
  }

  .yokohama-marker {
    left: 457px;
    top: 361px;
  }

  .manila-marker {
    left: 409px;
    top: 421px;
  }

  .techno-manila-marker {
    left: 409.5px;
    top: 422px;
  }

  .yangon-marker {
    left: 344px;
    top: 417px;
  }

  .bangkok-marker {
    left: 355px;
    top: 425.5px;
  }

  .dhaka-marker {
    left: 332px;
    top: 393.5px;
  }

  .shanghai-marker {
    left: 408px;
    top: 375.5px;
  }

  .beijing-marker {
    top: 348px;
    left: 398.5px;
  }

  .ho-chi-minh-marker {
    top: 430px;
    left: 372.5px;
  }

  .hanoi-marker {
    top: 402px;
    left: 370.5px;
  }

  .yangon2-marker {
    left: 343px;
    top: 416px;
  }

  .singapore-marker {
    left: 363px;
    top: 457.5px;
  }

  .kuala-lumpur-marker {
    left: 358px;
    top: 452.5px;
  }

  .id-marker {
    left: 374px;
    top: 481px;
  }

  .maharashtra-marker {
    left: 295.5px;
    top: 389px;
  }

  .germany-marker {
    left: 111px;
    top: 306px;
  }

  .usa-marker {
    left: 757px;
    top: 361px;
  }

  .bangkok2-marker {
    left: 358px;
    top: 424.5px;
  }

  .column {
    padding: 0;
  }
`
