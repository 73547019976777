import React, { useRef } from "react";
import HeroHeader from "../components/home/HeroHeader";
import HomeContent from "../components/home/HomeContent";
import Layout from "../components/shared/Layout";

const IndexPage = () => {
  const scrollRef = useRef(null);

  return (
    <Layout
      seoTitle="Home"
      seoKeywords="EPC Company in Malaysia, Best EPC Company in Malaysia, Japanese EPC Company in Malaysia, Japanese quality, EPC Company, JFE Locations, Global Company, Japanese EPC, JFE Engineering Malaysia Employees, JFEM Employees, JFEM, JFEM Events, Employee Gathering, Ramadhan, Sports, Social, JFE Engineering Malaysia Privacy Policy, JFEM Privacy Policy, JFEM Director, Energy, Environment, Construction, Sustainability, SDG, JFEM Quality Policy, JFEM Health Policy, JFEM Safety Policy, JFEM Environmental Policy, JFE Engineering Policy, JFEM Certifications, JFE Engineering Malaysia Certifications, Renewable Energy, Environment Services, General Construction, JFEM Projects, JFE Engineering Malaysia Projects, JFEM Services and Solutions, JFE Engineering Malaysia Services/Solutions, Lubricant, Petrochemical, General Manufacturing, Storage Facilities, Receiving Facilities, Pipeline, Geothermal, Waste Management, WWTP, WTP, WHR, Fresh Graduate, Career, Job, Engineering Career, Professional, Experienced, JFEM Job Openings, Engineering Job Openings, JFE Engineering Malaysia Job Openings, JFE Engineering Malaysia Hiring, Life at JFE, JFEM Job Application, Contact JFEM, Blood donor,"
    >
      <HeroHeader scrollRef={scrollRef} />
      <HomeContent scrollRef={scrollRef} />
    </Layout>
  );
};

export default IndexPage;
