import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"

export const HomeContainer = styled(Container)`
  position: relative;
  margin-top: 100px;
  padding-bottom: 100px;
  background: #f7f8fc;
  overflow: hidden;
  z-index: 1;

  #project-highlights {
    padding-bottom: 50px;
  }

  .centered {
    justify-content: center;
    align-items: center;
  }

  .with-padding {
    padding: 50px 10%;

    @media only screen and (max-width: 768px) {
      padding: 12px 5%;
    }
  }

  .circle-bg {
    position: absolute;
    bottom: -696px;
    left: -696px;
    z-index: -1;
  }

  .white-bg {
    background: #ffffff;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
  }

  h1 {
    text-align: center;
    margin-bottom: 25px;
  }

  .news-events-section {
    h1 {
      text-align: left;
    }

    p {
      font-weight: bold;
    }

    .news-date {
      min-width: 20%;

      @media only screen and (max-width: 1680px) and (min-width: 1367px) {
        min-width: 30%;
      }

      @media only screen and (max-width: 1367px) and (min-width: 1024px) {
        min-width: 25%;
      }

      @media only screen and (max-width: 1024px) and (min-width: 768px) {
        min-width: 0;
      }
    }

    .news-title {
      @media only screen and (max-width: 769px) {
        width: 100%;
      }
    }
  }

  .mobile-events-slider {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
`

export const StyledRow = styled(Row)`
  display: flex;
  position: relative;
  z-index: 0;
  padding-top: 25px;

  @media only screen and (max-width: 1025px) {
    .col-recent-news,
    .col-events {
      width: 100%;
    }
  }

  .col-events {
    @media only screen and (max-width: 1024px) and (min-width: 1px) {
      margin-top: 50px;
    }
  }

  .recent-news {
    margin-bottom: 25px;

    @media only screen and (min-width: 1023px) {
      min-height: 350px;
    }
  }

  .jfeem-offices-map {
    display: flex;
    justify-content: center;
    padding: 0;
    overflow: hidden;

    @media only screen and (max-width: 1367px) {
      height: 500px;
    }

    @media only screen and (max-width: 769px) {
      height: 400px;
    }

    @media only screen and (max-width: 500px) {
      height: 270px;
    }

    @media only screen and (max-width: 376px) {
      height: 200px;
    }
  }

  .malaysia {
    .title {
      color: #0073af;
    }
  }

  .hq {
    .title {
      color: #aac532;
    }
  }

  .title {
    @media only screen and (max-width: 1024px) {
      padding-left: 25px;
    }

    @media only screen and (max-width: 500px) {
      padding-left: 0;
      padding-top: 10px;
    }
  }

  .project-title {
    @media only screen and (max-width: 500px) {
      padding: 10px;
    }
  }

  .news {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    margin-bottom: 15px;

    @media only screen and (min-width: 1024px) and (max-width: 1367px) {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .jfeem {
    h1 {
      color: #0073af;
    }

    p {
      color: #149be1;
    }
  }

  .jfee {
    h1,
    p {
      color: #aac532;
    }

    svg {
      fill: #aac532;
      margin-bottom: 5px;
    }
  }

  .news-link {
    display: flex;
    margin-bottom: 25px;

    svg {
      margin-right: 10px;
    }

    h1 {
      text-align: left;
      margin: 0;

      @media only screen and (max-width: 500px) {
        line-height: 25px;
      }
    }

    p {
      text-align: left;
    }
  }
`

export const EventImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .event-images-container {
    display: flex;
    position: relative;
    width: 70%;

    h1 {
      font-size: 20px !important;

      @media only screen and (max-width: 1367px) {
        font-size: 16px !important;
      }
    }

    @media only screen and (min-width: 1025px) {
      width: 100%;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .featured-event-1 {
    position: absolute;
    right: 320px;
    transform: rotate(-10deg);
    z-index: 1;
  }

  .featured-event-2 {
    position: absolute;
    top: 0;
    right: 30px;
    transform: rotate(10deg);
    z-index: 0;
  }

  .featured-event-3 {
    position: absolute;
    top: 145px;
    right: 175px;
    transform: rotate(0);
    z-index: 2;
  }

  .featured-event-4 {
    position: absolute;
    top: 320px;
    right: 320px;
    transform: rotate(-10deg);
    z-index: 0;
  }

  .featured-event-5 {
    position: absolute;
    top: 320px;
    right: 30px;
    transform: rotate(10deg);
    z-index: 1;
  }

  @media only screen and (max-width: 1025px) and (min-width: 900px) {
    height: 500px;

    .featured-event-1 {
      left: 45px;
    }

    .featured-event-2 {
      top: 0;
      left: 270px;
    }

    .featured-event-3 {
      top: 115px;
      left: 160px;
    }

    .featured-event-4 {
      top: 250px;
      left: 45px;
    }

    .featured-event-5 {
      top: 250px;
      left: 275px;
    }
  }

  @media only screen and (width: 1025px) {
    height: 500px;
    .featured-event-1 {
      top: 50px;
      left: 150px;
    }

    .featured-event-2 {
      top: 50px;
      left: 390px;
    }

    .featured-event-3 {
      top: 165px;
      left: 270px;
    }

    .featured-event-4 {
      top: 300px;
      left: 150px;
    }

    .featured-event-5 {
      top: 300px;
      left: 390px;
    }
  }

  @media only screen and (max-width: 1550px) and (min-width: 1025px) {
    height: 500px;
    .featured-event-1 {
      top: 0;
      left: 5%;
    }

    .featured-event-2 {
      top: 0;
      left: 50%;
    }

    .featured-event-3 {
      top: 115px;
      left: 25%;
    }

    .featured-event-4 {
      top: 250px;
      left: 5%;
    }

    .featured-event-5 {
      top: 250px;
      left: 50%;
    }
  }

  @media only screen and (max-width: 900px) and (min-width: 768px) {
    height: 500px;
    .featured-event-1 {
      top: 50px;
      left: 0;
    }

    .featured-event-2 {
      top: 50px;
      left: 50%;
    }

    .featured-event-3 {
      top: 165px;
      left: 25%;
    }

    .featured-event-4 {
      top: 300px;
      left: 0;
    }

    .featured-event-5 {
      top: 300px;
      left: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

export const EventTitle = styled.div`
  height: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;

  h1 {
    text-align: center !important;
  }
`

export const EventFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 225px;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.3);
  padding: 5px;
  transition: 0.3s ease-in-out;

  h1 {
    margin-bottom: 5px;
  }

  &:hover {
    z-index: 3;
    transform: rotate(0);
    transform: scale(1.5, 1.5);
    transition: 0.5s ease-in-out;

    ${EventTitle} {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }
  }

  @media only screen and (max-width: 1550px) {
    width: 250px;
    height: 192.5px;

    &:hover {
      transform: scale(1.5, 1.5);

      ${EventTitle} {
        height: 50px;
      }
    }
  }
`

export const MobileEventFrame = styled.div`
  background: #ffffff;
  padding: 10px;
  margin: 0 10px;

  h1 {
    margin-bottom: 10px;
    text-align: center !important;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

export const StyledCol = styled(Col)`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .heading {
    padding: 10px;
    background: linear-gradient(180deg, #149be1 27.08%, #0073af 161.84%);
    border-radius: 20px;
    min-height: 160px;

    h1 {
      font-weight: bold;
      font-size: 88px;
      color: #ffffff;
      margin-bottom: 45px;

      @media only screen and (max-width: 769px) {
        font-size: 44px;
      }
    }

    @media only screen and (max-width: 1367px) {
      h1 {
        font-size: 68px;
      }
      min-height: 120px;
    }

    @media only screen and (max-width: 769px) {
      font-size: 44px;
      height: 120px;
      min-height: 120px;
    }
  }

  .body {
    transform: translateY(-10%);
    min-height: 335px;
    height: 400px;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 10px 10px;
    background: #fff;
    padding: 30px;

    .description {
      color: #0073af;
      text-align: center;
      margin-bottom: 25px;
    }

    .date {
      color: #8f9496;
      width: 100%;
      text-align: left;
      margin-top: 25px;
    }

    @media only screen and (max-width: 1367px) {
      height: 0;
    }
  }

  h1 {
    color: #00a5df;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
`

export const StyledSlider = styled(Slider)`
  padding: 0;

  .item-container {
    margin-bottom: 25px;
  }

  .item-container img {
    height: 450px;

    @media only screen and (max-width: 1367px) and (min-width: 1099px) {
      height: 325px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1099px) {
      height: 500px;
    }

    @media only screen and (max-width: 768px) {
      height: 50vw;
    }
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  .slick-track {
    display: flex;
  }

  @media only screen and (min-width: 1388px) {
    .slick-track {
      height: 100%;
    }
  }
`

export const CarouselItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;

  .carousel-img {
    width: 100%;
    height: 100%;
  }

  p {
    color: #ffffff;
    padding: 15px 30px;
    margin: 0 !important;
    white-space: normal;
  }

  .carousel-item-title {
    width: 100%;
    padding: inherit;
    position: absolute;
    bottom: 0;
    left: 0;

    .heading {
      overflow: hidden;
      background: linear-gradient(
        180deg,
        rgba(0, 115, 175, 0.9) 0%,
        rgba(20, 155, 225, 0.9) 0.01%,
        rgba(0, 115, 175, 0.9) 141.23%
      );

      p {
        font-weight: bold;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 5%;
  }

  @media only screen and (max-width: 500px) {
    p {
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
    }
  }
`
