import React, { useState } from "react"
import {
  CarouselItem,
  EventFrame,
  EventImages,
  EventTitle,
  HomeContainer,
  MobileEventFrame,
  StyledCol,
  StyledRow,
  StyledSlider,
} from "./HomeContent.styled"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import { easePolyOut } from "d3-ease"
import { useSpring, animated } from "react-spring"
import VisibilitySensor from "react-visibility-sensor"
import SvgMap from "../shared/SvgMap"
import JFEEMNews from "../../multimedia/JFEEM-News.svg"
import JFEENews from "../../multimedia/JFEE-News.svg"
import CircleBg from "../../../static/svg/Large-Circle.svg"
import {
  Heading24,
  Heading32,
  Heading40,
  Paragraph16,
  Paragraph18,
  StyledButton,
} from "../../styles/PageStyles"
import useWindowSize from "../shared/WindowDimensions"
import { RiExternalLinkLine } from "react-icons/ri"

const HomeContent = ({ scrollRef }) => {
  const [visibility, setVisibility] = useState(false)

  const { width } = useWindowSize()

  function carouselItemWidth() {
    if (width >= 1024 && width <= 1366) {
      return 600
    } else if (width < 1024 || width > 1366) {
      return 888
    } else {
      return 888
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "250px",
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1099,
        settings: {
          dots: true,
          infinite: true,
          focusOnSelect: false,
          centerMode: false,
          centerPadding: 0,
          variableWidth: false,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
        frontmatter {
          projectsHeading
          offices {
            hidden
            count
            title
            asOfDate
            officesImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          employees {
            hidden
            count
            title
            asOfDate
            employeesImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          jfeem_news {
            title
            subtitle
          }
          jfehq_news {
            title
            subtitle
          }
        }
      }
      projectHighlights: markdownRemark(
        frontmatter: { templateKey: { eq: "projects" } }
      ) {
        frontmatter {
          projects {
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              highlight {
                show
                title
              }
            }
          }
        }
      }
      newsId: allMarkdownRemark(
        filter: {
          frontmatter: { recent: { eq: true }, origin: { eq: "malaysia" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              origin
              title
              date(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
      newsHq: allMarkdownRemark(
        filter: { frontmatter: { recent: { eq: true }, origin: { eq: "hq" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              origin
              title
              link
              date(formatString: "MM/DD/YYYY")
            }
          }
        }
      }
      events: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(events)/" } }
        sort: { fields: frontmatter___sort_id, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnailImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 500
                    aspectRatio: 1.5
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { projects } = data.projectHighlights.frontmatter
  const newsId = data.newsId.edges
  const newsHq = data.newsHq.edges
  const events = data.events.edges

  function useAnimatedNumbers({ number }) {
    const animatedStyle = useSpring({
      val: number,
      from: { val: 0 },
      config: { duration: 2000, easing: easePolyOut },
    })

    return {
      style: animatedStyle,
    }
  }

  function AnimatedNumber(number) {
    const animationProps = useAnimatedNumbers(number)
    return (
      <>
        <animated.h1 {...animationProps} data-aos="fade-up">
          {animationProps.style.val.to((number) =>
            Math.floor(number).toLocaleString()
          )}
        </animated.h1>
      </>
    )
  }

  function onChangeVisibility(isVisible) {
    setVisibility(isVisible)
  }

  return (
    <HomeContainer fluid>
      {projects !== null && (
        <StyledRow ref={scrollRef} id="project-highlights">
          <Heading40
            data-aos="fade-up"
            data-aos-delay={1000}
            data-aos-offset={-100}
          >
            {frontmatter?.projectsHeading}
          </Heading40>
          <StyledSlider {...settings}>
            {projects?.map(
              (project, index) =>
                project?.images !== null &&
                project?.images.map(
                  (item, subIndex) =>
                    item?.highlight?.show && (
                      <div
                        key={index}
                        className="item-container"
                        style={{ width: carouselItemWidth() }}
                      >
                        <CarouselItem key={subIndex}>
                          {item?.image !== null ? (
                            <GatsbyImage
                              image={getImage(item?.image)}
                              alt={item?.highlight?.title}
                              className="carousel-img"
                              draggable={false}
                            />
                          ) : (
                            <StaticImage
                              src="../../multimedia/placeholder-image.png"
                              alt="Placeholder Image"
                              className="carousel-img"
                              draggable={false}
                            />
                          )}

                          <div className="carousel-item-title">
                            <div className="heading">
                              <Paragraph18 className="project-title">
                                {item?.highlight?.title !== null &&
                                  item?.highlight?.title}
                              </Paragraph18>
                            </div>
                          </div>
                        </CarouselItem>
                      </div>
                    )
                )
            )}
          </StyledSlider>
        </StyledRow>
      )}
      <StyledRow>
        <Heading40>JFE Engineering Offices Around the Globe</Heading40>
        <Col xs={12} className="jfeem-offices-map">
          <SvgMap />
        </Col>
      </StyledRow>
      <StyledRow className="with-padding centered">
        {!frontmatter?.offices?.hidden && (
          <StyledCol lg={6} md={12}>
            <div className="heading">
              <VisibilitySensor
                partialVisibility
                onChange={onChangeVisibility}
                active={!visibility}
              >
                <AnimatedNumber
                  number={
                    frontmatter?.offices?.count !== null
                      ? frontmatter?.offices?.count
                      : 0
                  }
                />
              </VisibilitySensor>
            </div>
            <div className="body">
              <Heading24 className="description">
                {frontmatter?.offices?.title}
              </Heading24>
              {frontmatter?.offices?.officesImage !== null ? (
                <GatsbyImage
                  image={getImage(frontmatter?.offices?.officesImage)}
                  alt="Overseas Offices and Subsidiaries"
                />
              ) : (
                <StaticImage
                  src="../../multimedia/placeholder-image.png"
                  alt="Placeholder Image"
                />
              )}

              <Paragraph16 className="date">
                {frontmatter?.offices?.asOfDate}
              </Paragraph16>
            </div>
          </StyledCol>
        )}
        {!frontmatter?.employees.hidden && (
          <StyledCol lg={6} md={12}>
            <div className="heading">
              <VisibilitySensor
                partialVisibility
                onChange={onChangeVisibility}
                active={!visibility}
              >
                <AnimatedNumber
                  visibility={visibility}
                  number={
                    frontmatter?.employees?.count !== null
                      ? frontmatter?.employees?.count
                      : 0
                  }
                />
              </VisibilitySensor>
            </div>
            <div className="body">
              <Heading24 className="description">
                {frontmatter?.employees?.title}
              </Heading24>
              {frontmatter?.employees?.employeesImage !== null ? (
                <GatsbyImage
                  image={getImage(frontmatter?.employees?.employeesImage)}
                  alt="Number of Employees"
                />
              ) : (
                <StaticImage
                  src="../../multimedia/placeholder-image.png"
                  alt="Placeholder Image"
                />
              )}
              <Paragraph16 className="date">
                {frontmatter?.employees?.asOfDate}
              </Paragraph16>
            </div>
          </StyledCol>
        )}
      </StyledRow>
      <StyledRow className="news-events-section with-padding">
        <Col className="col-recent-news" lg={6} md={12}>
          <Heading32>News</Heading32>
          <div className="recent-news">
            {newsId.map((news, index) => (
              <Row key={index}>
                <div className={`${news?.node?.frontmatter?.origin} news`}>
                  <Col className="news-date" lg={2} md={2}>
                    <Paragraph16 className="date">
                      {news?.node?.frontmatter?.date}
                    </Paragraph16>
                  </Col>
                  <Col className="news-title" lg={8}>
                    <Link to={news?.node?.fields?.slug}>
                      <Paragraph16 className="title">
                        {news?.node?.frontmatter?.title}
                      </Paragraph16>
                    </Link>
                  </Col>
                </div>
              </Row>
            ))}
            {newsHq.map((news, index) => (
              <Row key={index}>
                <div className={`${news?.node?.frontmatter?.origin} news`}>
                  <Col className="news-date" lg={2} md={2}>
                    <Paragraph16 className="date">
                      {news?.node?.frontmatter?.date}
                    </Paragraph16>
                  </Col>
                  <Col className="news-title" lg={8}>
                    <a
                      href={news?.node?.frontmatter?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Paragraph16 className="title">
                        {news?.node?.frontmatter?.title}
                      </Paragraph16>
                    </a>
                  </Col>
                </div>
              </Row>
            ))}
          </div>
          <div className="jfeem news-link">
            <JFEEMNews />
            <Link to="/news/jfe-engineering-malaysia/">
              <Heading24>{frontmatter?.jfeem_news?.title}</Heading24>
              <Paragraph16>{frontmatter?.jfeem_news?.subtitle}</Paragraph16>
            </Link>
          </div>
          <div className="jfee news-link">
            <JFEENews />
            <a
              href="https://www.jfe-eng.co.jp/en/news/"
              target="_blank"
              rel="noreferrer"
            >
              <Heading24>
                {frontmatter?.jfehq_news?.title}&nbsp;
                <RiExternalLinkLine />
              </Heading24>
              <Paragraph16>{frontmatter?.jfehq_news?.subtitle}</Paragraph16>
            </a>
          </div>
        </Col>
        <Col className="col-events" lg={6} md={12}>
          <Heading32>Events</Heading32>
          <EventImages>
            <div className="event-images-container">
              {events.map((event, index) => (
                <Link key={index} to={event.node?.fields?.slug}>
                  <EventFrame className={`featured-event-${index + 1}`}>
                    <EventTitle>
                      <Heading24>{event?.node?.frontmatter?.title}</Heading24>
                    </EventTitle>
                    {event?.node?.frontmatter?.thumbnailImage !== null ? (
                      <GatsbyImage
                        image={getImage(
                          event?.node?.frontmatter?.thumbnailImage
                        )}
                        alt={event?.node?.frontmatter?.title}
                      />
                    ) : (
                      <StaticImage
                        src="../../multimedia/placeholder-image.png"
                        alt="Placeholder Image"
                      />
                    )}
                  </EventFrame>
                </Link>
              ))}
            </div>
          </EventImages>
          <StyledSlider {...settings} className="mobile-events-slider">
            {events.map((event, index) => (
              <Link key={index} to={event?.node?.fields?.slug}>
                <MobileEventFrame>
                  <Heading24>{event?.node?.frontmatter?.title}</Heading24>
                  {event?.node?.frontmatter?.thumbnailImage !== null ? (
                    <GatsbyImage
                      image={getImage(event?.node?.frontmatter?.thumbnailImage)}
                      alt={event?.node?.frontmatter?.title}
                    />
                  ) : (
                    <StaticImage
                      src="../../multimedia/placeholder-image.png"
                      alt="Placeholder Image"
                    />
                  )}
                </MobileEventFrame>
              </Link>
            ))}
          </StyledSlider>
        </Col>
      </StyledRow>
      <StyledRow className="centered">
        <StyledButton to="/privacy-policy/" style={{ marginTop: 50 }}>
          <Paragraph18>View Privacy Policy</Paragraph18>
        </StyledButton>
      </StyledRow>
      <CircleBg className="circle-bg" />
    </HomeContainer>
  )
}

export default HomeContent
