import React, { useEffect } from "react"
import {
  HeroContainer,
  Background,
  ScrollButton,
  Video,
  UnmuteButton,
} from "./HeroHeader.styled"
import useWindowSize from "../shared/WindowDimensions"
import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import { VscUnmute, VscMute } from "react-icons/vsc"

const HeroHeader = ({ scrollRef }) => {
  const { width } = useWindowSize()
  const [mute, setMute] = useState(false)
  const [video, setVideo] = useState()
  const handleToggleMute = () => {
    setMute(!mute)
    video.muted = mute
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    function getVideoElement() {
      setVideo(document.getElementById("videoHeader"))
    }
    getVideoElement()
  })

  const handleScrollDown = () => {
    scrollRef.current.scrollIntoView()
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
        frontmatter {
          videoHeader {
            publicURL
            internal {
              mediaType
            }
          }
          videoHeaderFallback {
            publicURL
            internal {
              mediaType
            }
          }
          mobileVideoHeader {
            publicURL
            internal {
              mediaType
            }
          }
        }
      }
    }
  `)

  const { videoHeader, videoHeaderFallback, mobileVideoHeader } =
    data.markdownRemark.frontmatter

  return (
    <HeroContainer>
      <Background>
        {width <= 915 && (
          <Video id="videoHeader" autoPlay muted loop playsInline>
            <source
              src={mobileVideoHeader?.publicURL}
              type={mobileVideoHeader?.internal?.mediaType}
            />
          </Video>
        )}
        {width > 915 && (
          <Video id="videoHeader" autoPlay muted loop playsInline>
            <source
              src={videoHeader?.publicURL}
              type={videoHeader?.internal?.mediaType}
            />
            <source
              src={videoHeaderFallback?.publicURL}
              type={videoHeaderFallback?.internal?.mediaType}
            />
          </Video>
        )}
      </Background>
      {width > 915 && (
        <>
          <ScrollButton onClick={handleScrollDown}>
            <div>
              <span></span>
            </div>
          </ScrollButton>
          <UnmuteButton onClick={handleToggleMute}>
            {mute ? <VscUnmute size={25} /> : <VscMute size={25} />}
          </UnmuteButton>
        </>
      )}
      {width <= 915 && (
        <UnmuteButton onClick={handleToggleMute}>
          {mute ? <VscUnmute size={20} /> : <VscMute size={20} />}
        </UnmuteButton>
      )}
    </HeroContainer>
  )
}

export default HeroHeader
